import { useTenants } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { TenantDetailsView } from './TenantDetailsView';

interface Props {
  tenantId: string;
}

export function TenantDetailsQueryResult(props: Props) {
  const { tenantId } = props;
  const queryResult = useTenants(); // @TODO fetch only one tenant

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => {
        const tenant = data.tenants.find((t) => t.id === tenantId);
        return tenant ? <TenantDetailsView tenant={tenant} /> : <NotFound />;
      }}
    </QueryResult>
  );
}
