import { TenantsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useTenants(allMotionTenants?: boolean) {
  const url = allMotionTenants
    ? `/v2/orm/tenant?allMotionTenants=true`
    : `/v2/orm/tenant`;
  const request = new Request(url);

  return useAuthenticatedRequest<TenantsDTO>(request, {
    queryKey: ['tenants'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useTenants;
