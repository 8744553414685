import { NavItem } from '@hakimo-ui/hakimo/ui-navigation';
import { RectangleGroupIcon, UserGroupIcon } from '@heroicons/react/24/outline';

export const navItems: NavItem[] = [
  {
    id: 'tenants',
    name: 'Tenants',
    href: '/tenants',
    icon: RectangleGroupIcon,
    requiredPermissions: ['admin_ui:access'],
  },
  {
    id: 'user-management',
    name: 'User Management',
    href: '/user-management',
    icon: UserGroupIcon,
    requiredPermissions: ['admin_ui:access'],
  },
];
