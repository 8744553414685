import {
  ModifyUserMspTenantsAction,
  User,
  UserDTO,
} from '@hakimo-ui/hakimo/types';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { Table } from '@hakimo-ui/hakimo/ui-table';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { UpdateTenant } from './assign-tenant/UpdateTenant';
import { useGetManagedUsers } from './useGetManagedUsers';
import { getTableData } from './util';

export function UserList() {
  const [users, setUsers] = useState<Record<string, User>>({});
  const [selected, setSelected] = useState<string[]>([]);
  const [updateAction, setUpdateAction] =
    useState<ModifyUserMspTenantsAction>();

  const usersQueryResult = useGetManagedUsers((data) => {
    const userMap = data.items.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {} as Record<string, User>);
    setUsers(userMap);
  });

  const getTable = (data: UserDTO) => {
    const tableData = getTableData(data.items);

    const tableHeader = (
      <div className="flex h-20 items-center px-4">
        {selected.length > 0 ? (
          <div className="flex items-center gap-4">
            <Button variant="primary" onClick={() => setUpdateAction('assign')}>
              Assign MSP Tenant
            </Button>
            <Button variant="primary" onClick={() => setUpdateAction('remove')}>
              Remove MSP Tenant
            </Button>
          </div>
        ) : (
          <h2 className="text-xl">Users</h2>
        )}
      </div>
    );

    return (
      <Table
        data={tableData}
        rowSelection={{
          selected,
          onChangeSelected: setSelected,
        }}
        header={tableHeader}
      ></Table>
    );
  };

  return (
    <>
      <QueryResult queryResult={usersQueryResult}>{getTable}</QueryResult>
      {updateAction && (
        <UpdateTenant
          selectedUsers={selected.map((id) => users[id])}
          action={updateAction}
          onClose={() => setUpdateAction(undefined)}
        />
      )}
    </>
  );
}
