import { useTenants } from '@hakimo-ui/hakimo/data-access';
import { Tenant, TenantsDTO } from '@hakimo-ui/hakimo/types';
import { QueryResult, Select } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  hakimoSupportUpdatingSelf: boolean;
  onChange: (tenant: Tenant | null) => void;
}

export function SelectTenant(props: Props) {
  const { hakimoSupportUpdatingSelf, onChange } = props;
  const tenantsQueryResult = useTenants(hakimoSupportUpdatingSelf);

  const getOptions = (data: TenantsDTO, query: string) => {
    return data.tenants.filter((tenant) =>
      tenant.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <QueryResult queryResult={tenantsQueryResult}>
      {(data) => {
        return (
          <Select
            label="Tenant"
            getItems={(query: string) => getOptions(data, query)}
            displayValue={(item) => item?.name ?? ''}
            onChange={onChange}
          />
        );
      }}
    </QueryResult>
  );
}
