import { ModifyUserMspTenantsPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateMspTenants(onSuccess: () => void) {
  const url = `/v2/orm/user/mspTenants`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, ModifyUserMspTenantsPayload>(request, {
    onSuccessInvalidationKeys: [['admin', 'users']],
    onSuccess,
  });
}
