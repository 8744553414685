import { UserManagementRoot } from '@hakimo-ui/feature-user-management';
import {
  TenantDetails,
  TenantList,
  TenantsRoot,
} from '@hakimo-ui/hakimo-admin/feature-tenants';
import {
  AppInitOptions,
  AppMain,
  Login,
} from '@hakimo-ui/hakimo/feature-shared';
import { UserProfileRoot } from '@hakimo-ui/hakimo/feature-user-profile';
import { AuthProvider, HttpClientProvider } from '@hakimo-ui/hakimo/providers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { INTERCOM_APP_ID, SENTRY_DSN, isLocal } from './config';
import { DefaultRoute } from './routing/DefaultRoute';
import { navItems } from './routing/nav-items';

function Providers() {
  const queryClient = new QueryClient();
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HttpClientProvider>
          <Outlet />
        </HttpClientProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

function getRouter() {
  const appInitOptions: AppInitOptions = {
    sentryDsn: SENTRY_DSN,
    sentryEnabled: !isLocal,
    amplitudeEnabled: !isLocal,
    intercomAppId: INTERCOM_APP_ID,
    intercomEnabled: !isLocal,
    intercomSource: 'hakimo-admin',
  };

  const router = createBrowserRouter([
    {
      element: <Providers />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          element: (
            <AppMain navItems={navItems} appInitOptions={appInitOptions} />
          ),
          children: [
            {
              path: '/',
              element: <DefaultRoute />,
            },
            {
              path: 'tenants',
              element: <TenantsRoot />,
              children: [
                {
                  index: true,
                  element: <TenantList />,
                },
                {
                  path: ':tenantId',
                  element: <TenantDetails />,
                },
              ],
            },
            {
              path: '/user-management',
              element: <UserManagementRoot />,
            },
            {
              path: '/profile/*',
              element: <UserProfileRoot />,
            },
          ],
        },
      ],
    },
  ]);

  return router;
}

export default getRouter;
