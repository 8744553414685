import { User } from '@hakimo-ui/hakimo/types';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import { Label } from '@hakimo-ui/shared/ui-base';

export function getTableData(users: User[]) {
  const tableData: TableData = {
    columns: [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'email',
        name: 'Email',
      },
      {
        id: 'tenant',
        name: 'Tenant',
      },
      {
        id: 'msp-tenants',
        name: 'MSP Tenants',
      },
    ],
    rows: users.map((user: User) => ({
      id: user.id || user.email,
      cells: [
        {
          value: <span className="flex items-center gap-2">{user.name}</span>,
        },
        {
          value: user.email,
        },
        {
          value: user.tenant.name,
        },
        {
          value: user.mspTenants ? (
            <div className="flex flex-wrap gap-2">
              {user.mspTenants.map((tenant) => (
                <Label key={tenant.id} small>
                  {tenant.name}
                </Label>
              ))}
            </div>
          ) : (
            ''
          ),
        },
      ],
    })),
  };

  return tableData;
}
