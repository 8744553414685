import {
  getUserListSearchParams,
  useUsers,
} from '@hakimo-ui/hakimo/data-access';
import { UserDTO } from '@hakimo-ui/hakimo/types';
import { useUser } from '@hakimo-ui/hakimo/util';

export function useGetManagedUsers(onSuccess: (data: UserDTO) => void) {
  const user = useUser();
  const tenants = new Set([user.tenant.id]);
  user.mspTenants?.forEach((tenant) => tenants.add(tenant.id));
  const queryParams = getUserListSearchParams({ tenants: Array.from(tenants) });
  return useUsers(queryParams, onSuccess);
}
