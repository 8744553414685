import { useUpdateMspTenants } from '@hakimo-ui/hakimo/data-access';
import {
  ModifyUserMspTenantsAction,
  Tenant,
  User,
} from '@hakimo-ui/hakimo/types';
import { toast, useIsSupportRole, useUser } from '@hakimo-ui/hakimo/util';
import { Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { SelectTenant } from './SelectTenant';

interface Props {
  selectedUsers: User[];
  action: ModifyUserMspTenantsAction;
  onClose: () => void;
}

export function UpdateTenant(props: Props) {
  const { selectedUsers, action, onClose } = props;
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const user = useUser();
  const isSupportRole = useIsSupportRole();

  const updateMutation = useUpdateMspTenants(() => {
    toast(`Tenant ${action === 'assign' ? 'assigned' : 'removed'}`, {
      type: 'success',
    });
    onClose();
  });

  const hakimoSupportUpdatingSelf =
    selectedUsers.length === 1 &&
    selectedUsers[0].id === user.id &&
    isSupportRole;

  const onSubmit = () => {
    if (tenant) {
      updateMutation.mutate({
        users: selectedUsers.map((u) => u.id),
        mspTenants: [tenant.id],
        action,
      });
    }
  };

  const actions = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          disabled={!tenant || updateMutation.isLoading}
          onClick={onSubmit}
          loading={updateMutation.isLoading}
        >
          Submit
        </Button>
      </span>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      title={`${action === 'assign' ? 'Assign' : 'Remove'} Tenant`}
      open={true}
      onClose={onClose}
      footer={actions}
    >
      <div className="w-[24rem] px-8 py-4">
        <SelectTenant
          hakimoSupportUpdatingSelf={hakimoSupportUpdatingSelf}
          onChange={setTenant}
        />
      </div>
    </Modal>
  );
}
