import { Alert } from '@hakimo-ui/shared/ui-base';
import { useParams } from 'react-router-dom';
import { TenantDetailsQueryResult } from './TenantDetailsQueryResult';

interface Params extends Record<string, string> {
  tenantId: string;
}

export function TenantDetails() {
  const { tenantId } = useParams<Params>();

  if (!tenantId) {
    return <Alert type="error">Missing tenant ID in URL</Alert>;
  }

  return <TenantDetailsQueryResult tenantId={tenantId} />;
}
