import { Link } from 'react-router-dom';
import { Tenant } from '../types';

interface Props {
  tenant: Tenant;
}

export function TenantCard(props: Props) {
  const { tenant } = props;
  return (
    <Link
      to={tenant.id}
      className="dark:bg-ondark-bg-2 bg-onlight-bg-2 dark:hover:bg-ondark-bg-3 hover:bg-onlight-bg-3 rounded p-4 text-left"
    >
      <h3 className="text-lg">{tenant.name}</h3>
      <p className="dark:text-ondark-text-2 text-onlight-text-2 mt-1 text-xs">
        {tenant.id}
      </p>
    </Link>
  );
}
