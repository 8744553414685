import { CameraList } from '@hakimo-ui/hakimo/feature-cameras';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Tabs } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tenant } from '../types';
import { TabItemId } from './types';
import { tabItems } from './util';

interface Props {
  tenant: Tenant;
}

export function TenantDetailsView(props: Props) {
  const { tenant } = props;
  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState<TabItemId>('cameras');

  const onClickBack = () => {
    navigate('/tenants');
  };

  return (
    <Page title={tenant.name} onClickBack={onClickBack}>
      <>
        <div className="mb-4">
          <Tabs
            items={tabItems}
            activeTabId={activeTabId}
            onChange={setActiveTabId}
          />
        </div>
        {activeTabId === 'cameras' && <CameraList tenantIds={[tenant.id]} />}
      </>
    </Page>
  );
}
