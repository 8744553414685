import { useTenants } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { TenantCard } from './TenantCard';

export function TenantList() {
  const tenantsQueryResult = useTenants();

  return (
    <Page title="Tenants">
      <QueryResult queryResult={tenantsQueryResult}>
        {(data) => (
          <div className="space-y-4">
            <h2 className="text-xl">My Tenants</h2>
            <div className="grid grid-cols-4 gap-3">
              {data.tenants.map((tenant) => (
                <TenantCard key={tenant.id} tenant={tenant} />
              ))}
            </div>
          </div>
        )}
      </QueryResult>
    </Page>
  );
}
