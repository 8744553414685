import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { UserList } from './UserList';

export function UserManagementRoot() {
  return (
    <Page title="User Management">
      <UserList />
    </Page>
  );
}
